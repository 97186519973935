import { defineComponent } from 'vue';
import { backendUrl, Translation } from '@/models';
import playAudio from '@/components/student/study/audioMethods';

export const audioMixin = defineComponent({
  emits: ['playAudio'],
  data () {
    return {
      audioQueue: [] as Translation[],
      audioPlaying: false
    };
  },
  methods: {
    playVocab (translation?: Translation) {
      if (!this.$store.state.userSettings.audiosWhileLearning || !translation) {
        return;
      }
      if (this.audioPlaying) {
        this.audioQueue.push(translation);
        return;
      }
      this.$emit('playAudio', false);
      this.audioPlaying = true;
      playAudio(backendUrl + translation.toAudioUrl).then(() => {
        if (this.audioQueue.length !== 0) {
          this.audioPlaying = false;
          setTimeout(() => this.playVocab(this.audioQueue.shift()), 2000);
          return;
        }
        setTimeout(() => this.$emit('playAudio', true), 2000);
        this.audioPlaying = false;
      });
    }
  }
});
