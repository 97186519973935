
import { defineComponent } from 'vue';
import StudyForm from '@/components/student/study/StudyForm.vue';
import { Study, Translation, errorPrepend } from '@/models';
import { showError } from '@/sweetalert2/templates.ts';
import Loading from '@/components/common/Loading.vue';

export default defineComponent({
  components: { Loading, StudyForm },
  data () {
    return {
      allTranslations: [] as Translation[],
      finishedLoading: false,
      lessonIds: [] as number[],
      trainingRounds: 0
    };
  },
  async mounted () {
    this.lessonIds = this.getLessonIds();
    try {
      this.allTranslations = await this.getAllTranslations(this.lessonIds);
    } catch (e) {
      await showError(this.$t("messages.no start training") + errorPrepend());
    }
    this.finishedLoading = true;
  },
  methods: {
    async trainAgain () {
      try {
        this.allTranslations = await this.getAllTranslations(this.lessonIds);
      } catch (e) {
        await showError(this.$t("messages.no start training") + errorPrepend());
      }
      this.trainingRounds++;
    },
    async getAllTranslations (lessonIds: number[]) {
      const lessonTranslations = await Promise.all(lessonIds.map(id => Translation.getTranslationsFromLesson(id)));
      const allTranslations: Translation[] = [];
      for (const lessonTranslation of lessonTranslations) {
        allTranslations.push(...lessonTranslation);
      }
      return allTranslations;
    },
    getLessonIds (): number[] {
      const lastLessons = localStorage.getItem('studyingLessons');
      if (Object.keys(this.$route.params).length !== 0) {
        const lessonIds = this.$route.params.lessonIds;
        localStorage.setItem('studyingLessons', JSON.stringify(lessonIds));
      } else if (lastLessons == null) {
        this.$router.back();
        return [];
      }
      const lessons = JSON.parse(localStorage.getItem('studyingLessons') ?? '');
      return lessons.map((id: string) => parseInt(id));
    }
  }
});

