import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100 d-flex flex-wrap" }
const _hoisted_2 = { class: "container-fluid px-xl-5" }
const _hoisted_3 = { class: "py-0" }
const _hoisted_4 = {
  id: "studyForm",
  class: "row justify-content-center"
}
const _hoisted_5 = { class: "col-lg-12 col-md-8 col-sm-10 py-4" }
const _hoisted_6 = { class: "row justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StudyForm = _resolveComponent("StudyForm")!
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("section", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.finishedLoading)
              ? (_openBlock(), _createBlock(_component_StudyForm, {
                  "all-translations": _ctx.allTranslations,
                  lessonIds: _ctx.lessonIds,
                  key: _ctx.trainingRounds,
                  onTrainAgain: _ctx.trainAgain
                }, null, 8, ["all-translations", "lessonIds", "onTrainAgain"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_Loading, {
                loading: !_ctx.finishedLoading
              }, null, 8, ["loading"])
            ])
          ])
        ])
      ])
    ])
  ]))
}