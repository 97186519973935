
import { defineComponent, PropType } from 'vue';
import { Translation } from '@/models';
import { levDist } from '@/components/student/study/StudyMethods';

export default defineComponent({
  props: {
    translations: {
      type: Object as PropType<Translation[]>,
      required: true
    }
  },
  emits: ['checkOne', 'submitted'],
  methods: {
    check () {
      let flawless = true;
      for (const translation of this.translations) {
        if (levDist(translation.toName, translation.guess || '') > 1) {
          flawless = false;
        }
        this.$emit('checkOne', {
          translation,
          term: translation.guess || ''
        });
      }

      this.$emit('submitted', flawless);
    }
  }
});
