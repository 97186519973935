import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-04f92896")
const _hoisted_1 = {
  key: 0,
  class: "row justify-content-center"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.superlearning.audioUrl)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("button", {
          class: "btn btn-primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleAudio()))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(['fa', _ctx.paused ? 'fa-pause': 'fa-play'])
          }, null, 2)
        ]),
        _withDirectives(_createElementVNode("input", {
          class: "mx-2",
          type: "range",
          min: "0",
          max: "1",
          step: "0.05",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.volume = $event))
        }, null, 512), [
          [_vModelText, _ctx.volume]
        ]),
        _createElementVNode("button", {
          class: "btn btn-primary",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.mute && _ctx.mute(...args)))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(['fas', _ctx.volume < 0.5 ? 'fa-volume-down' : 'fa-volume-up'])
          }, null, 2)
        ])
      ]))
    : _createCommentVNode("", true)
}