import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-53fb23c4")
const _hoisted_1 = {
  key: 0,
  id: "image1of3",
  class: "card d-block"
}
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "row mx-2 d-flex align-items-center" }
const _hoisted_4 = {
  ref: "swal-container",
  id: "swal-container",
  class: "position-relative mt-3"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OneOfThree = _resolveComponent("OneOfThree")!
  const _component_ImageOneOfThree = _resolveComponent("ImageOneOfThree")!
  const _component_ImageAudioOneOfThree = _resolveComponent("ImageAudioOneOfThree")!
  const _component_WordCluster = _resolveComponent("WordCluster")!
  const _component_WordList = _resolveComponent("WordList")!
  const _component_WriteWord = _resolveComponent("WriteWord")!
  const _component_ImageWriteWord = _resolveComponent("ImageWriteWord")!
  const _component_ImageAudioWriteWord = _resolveComponent("ImageAudioWriteWord")!
  const _component_progress_bar = _resolveComponent("progress-bar")!
  const _component_study_music = _resolveComponent("study-music")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.currentStage.method !=='None')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.currentStage.method=== 'OneOfThree')
              ? (_openBlock(), _createBlock(_component_OneOfThree, {
                  key: 0,
                  translations: _ctx.currentStage.usesTranslations,
                  learns: _ctx.currentStage.learnsTranslations[0],
                  onPlayAudio: _cache[0] || (_cache[0] = ($event: any) => ($event ? _ctx.audioPlaying++ : _ctx.audioPlaying--)),
                  onCheckOne: _cache[1] || (_cache[1] = ($event: any) => (_ctx.sendGuess($event))),
                  onSubmitted: _cache[2] || (_cache[2] = ($event: any) => (_ctx.displayNewCard($event)))
                }, null, 8, ["translations", "learns"]))
              : _createCommentVNode("", true),
            (_ctx.currentStage.method==='ImageOneOfThree')
              ? (_openBlock(), _createBlock(_component_ImageOneOfThree, {
                  key: 1,
                  translations: _ctx.currentStage.usesTranslations,
                  learns: _ctx.currentStage.learnsTranslations[0],
                  onPlayAudio: _cache[3] || (_cache[3] = ($event: any) => ($event ? _ctx.audioPlaying++ : _ctx.audioPlaying--)),
                  onCheckOne: _cache[4] || (_cache[4] = ($event: any) => (_ctx.sendGuess($event))),
                  onSubmitted: _cache[5] || (_cache[5] = ($event: any) => (_ctx.displayNewCard($event)))
                }, null, 8, ["translations", "learns"]))
              : _createCommentVNode("", true),
            (_ctx.currentStage.method==='ImageAudioOneOfThree')
              ? (_openBlock(), _createBlock(_component_ImageAudioOneOfThree, {
                  key: 2,
                  translations: _ctx.currentStage.usesTranslations,
                  learns: _ctx.currentStage.learnsTranslations[0],
                  onCheckOne: _cache[6] || (_cache[6] = ($event: any) => (_ctx.sendGuess($event))),
                  onSubmitted: _cache[7] || (_cache[7] = ($event: any) => (_ctx.displayNewCard($event))),
                  onPlayAudio: _cache[8] || (_cache[8] = ($event: any) => ($event ? _ctx.audioPlaying++ : _ctx.audioPlaying--))
                }, null, 8, ["translations", "learns"]))
              : _createCommentVNode("", true),
            (_ctx.currentStage.method==='WordCluster')
              ? (_openBlock(), _createBlock(_component_WordCluster, {
                  key: 3,
                  translations: _ctx.currentStage.usesTranslations,
                  onCheckOne: _cache[9] || (_cache[9] = ($event: any) => (_ctx.sendGuess($event))),
                  onPlayAudio: _cache[10] || (_cache[10] = ($event: any) => ($event ? _ctx.audioPlaying++ : _ctx.audioPlaying--)),
                  onSubmitted: _cache[11] || (_cache[11] = ($event: any) => (_ctx.displayNewCard($event)))
                }, null, 8, ["translations"]))
              : _createCommentVNode("", true),
            (_ctx.currentStage.method==='WordList')
              ? (_openBlock(), _createBlock(_component_WordList, {
                  key: 4,
                  translations: _ctx.currentStage.usesTranslations,
                  onCheckOne: _cache[12] || (_cache[12] = ($event: any) => (_ctx.sendGuess($event))),
                  onSubmitted: _cache[13] || (_cache[13] = ($event: any) => (_ctx.displayNewCard($event)))
                }, null, 8, ["translations"]))
              : _createCommentVNode("", true),
            (_ctx.currentStage.method==='WriteWord')
              ? (_openBlock(), _createBlock(_component_WriteWord, {
                  key: 5,
                  translation: _ctx.currentStage.usesTranslations[0],
                  onCheckOne: _cache[14] || (_cache[14] = ($event: any) => (_ctx.sendGuess($event))),
                  onPlayAudio: _cache[15] || (_cache[15] = ($event: any) => ($event ? _ctx.audioPlaying++ : _ctx.audioPlaying--)),
                  onSubmitted: _cache[16] || (_cache[16] = ($event: any) => (_ctx.displayNewCard($event)))
                }, null, 8, ["translation"]))
              : _createCommentVNode("", true),
            (_ctx.currentStage.method==='ImageWriteWord')
              ? (_openBlock(), _createBlock(_component_ImageWriteWord, {
                  key: 6,
                  translation: _ctx.currentStage.usesTranslations[0],
                  onCheckOne: _cache[17] || (_cache[17] = ($event: any) => (_ctx.sendGuess($event))),
                  onPlayAudio: _cache[18] || (_cache[18] = ($event: any) => ($event ? _ctx.audioPlaying++ : _ctx.audioPlaying--)),
                  onSubmitted: _cache[19] || (_cache[19] = ($event: any) => (_ctx.displayNewCard($event)))
                }, null, 8, ["translation"]))
              : _createCommentVNode("", true),
            (_ctx.currentStage.method==='ImageAudioWriteWord')
              ? (_openBlock(), _createBlock(_component_ImageAudioWriteWord, {
                  key: 7,
                  translation: _ctx.currentStage.usesTranslations[0],
                  onCheckOne: _cache[20] || (_cache[20] = ($event: any) => (_ctx.sendGuess($event))),
                  onSubmitted: _cache[21] || (_cache[21] = ($event: any) => (_ctx.displayNewCard($event))),
                  onPlayAudio: _cache[22] || (_cache[22] = ($event: any) => ($event ? _ctx.audioPlaying++ : _ctx.audioPlaying--))
                }, null, 8, ["translation"]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_progress_bar, {
        class: _normalizeClass(['col-12',  _ctx.superlearning.audioUrl ? 'col-lg-7' : '', 'mt-4', 'px-0']),
        width: _ctx.countProgress*100
      }, null, 8, ["class", "width"]),
      _createVNode(_component_study_music, {
        superlearning: _ctx.superlearning,
        class: "col-12 col-lg-5 mt-4 d-flex justify-content-center",
        playing: _ctx.audioPlaying === 0
      }, null, 8, ["superlearning", "playing"])
    ]),
    _createElementVNode("div", _hoisted_4, null, 512)
  ], 64))
}