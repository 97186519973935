
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ImageContainer',
  props: {
    imageUrl: String,
    description: String
  }
});
