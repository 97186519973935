import { Translation } from '@/models';

export function shuffle<T> (vocabs: T[]): T[] {
  const randomArray: T[] = [];

  while (vocabs.length !== 0) {
    const randVal = Math.floor(Math.random() * (vocabs.length));
    randomArray.push(vocabs[randVal]);
    vocabs.splice(randVal, 1);
  }

  return randomArray;
}

export function getRandomWeightedValues (translations: Translation[], amount: number, inverted = true): Translation[] {
  const translationArray: Translation[] = [...translations];
  const randomValues: Translation[] = [];
  const length = translationArray.length;

  if (amount <= 0) {
    return randomValues;
  }

  for (let i = 0; i < amount && i < length; i++) {
    let valueSum = 0;
    valueSum = translationArray.reduce((a, b) => a + (inverted ? b.invertedValue ?? 101 : b.level), 0);

    const randValue = Math.floor(Math.random() * valueSum);
    let sum = 0;

    for (const translation of translationArray) {
      sum += inverted ? translation.invertedValue || 0 : translation.level || 0;

      if (sum >= randValue) {
        randomValues.push(translation);

        const index = translationArray.indexOf(translation);
        if (index > -1) {
          translationArray.splice(index, 1);
        }
        break;
      }
    }
  }
  return randomValues;
}

export function checkCorrect (guess?: Translation, should?: Translation): boolean {
  if (guess === undefined || should === undefined) { return false; }
  if (guess === should) {
    return true;
  }
  return guess.fromId === should.fromId || guess.toId === should.toId;
}
export function levDist (s: string, t: string): number {
  // levenshtein
  const d: number[][] = []; // 2d matrix

  // Step 1
  const n = s.length;
  const m = t.length;

  if (n === 0) return m;
  if (m === 0) return n;

  // Create an array of arrays in javascript (a descending loop is quicker)
  for (let i = n; i >= 0; i--) d[i] = [];

  // Step 2
  for (let i = n; i >= 0; i--) d[i][0] = i;
  for (let j = m; j >= 0; j--) d[0][j] = j;

  // Step 3
  for (let i = 1; i <= n; i++) {
    const s1 = s.charAt(i - 1);

    // Step 4
    for (let j = 1; j <= m; j++) {
      // Check the jagged ld total so far
      if (i === j && d[i][j] > 4) return n;

      const tJ = t.charAt(j - 1);
      const cost = (s1 === tJ) ? 0 : 1; // Step 5

      // Calculate the minimum
      let mi = d[i - 1][j] + 1;
      const b = d[i][j - 1] + 1;
      const c = d[i - 1][j - 1] + cost;

      if (b < mi) mi = b;
      if (c < mi) mi = c;

      d[i][j] = mi; // Step 6

      // Damerau transposition
      if (i > 1 && j > 1 && s1 === t.charAt(j - 2) && s.charAt(i - 2) === tJ) {
        d[i][j] = Math.min(d[i][j], d[i - 2][j - 2] + cost);
      }
    }
  }
  // Step 7
  return d[n][m];
}
