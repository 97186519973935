
import { defineComponent, PropType } from 'vue';
import { Superlearning, backendUrl } from '@/models';

export default defineComponent({
  props: {
    playing: {
      type: Boolean
    },
    superlearning: { type: Object as PropType<Superlearning>, required: true }
  },

  data () {
    return {
      volume: 1,
      paused: false,
      prevVolume: 1,
      audio: {} as HTMLAudioElement
    };
  },
  methods: {
    toggleAudio (value?: boolean) {
      this.paused = value ?? this.audio.paused;
      if (value ?? this.audio.paused) {
        this.audio.play();
      } else {
        this.audio.pause();
      }
    },
    mute () {
      if (parseFloat('' + this.volume) !== 0) {
        this.prevVolume = this.volume;
        this.volume = 0;
      } else {
        this.volume = this.prevVolume;
      }
    }
  },
  beforeUnmount () {
    if (!this.superlearning.audioUrl) { return; }
    localStorage.setItem('audioVolume', JSON.stringify(this.volume ?? 1));
    localStorage.setItem('audioTime', JSON.stringify(this.audio.currentTime ?? 0));
    this.audio.pause();
  },
  watch: {
    superlearning (value) {
      this.audio = new Audio(backendUrl + '/' + value.audioUrl);
      this.audio.loop = true;
      this.volume = JSON.parse(localStorage.getItem('audioVolume') ?? '1');
      this.audio.currentTime = JSON.parse(localStorage.getItem('audioTime') ?? '0');
      this.audio.onload = () => { this.paused = this.audio.paused; };
    },
    playing (value: boolean) {
      if (value) {
        this.volume = this.prevVolume;
      } else {
        this.prevVolume = this.volume;
        this.volume = Math.min(this.volume, 0.1);
      }
    },
    volume (value: number) {
      this.audio.volume = value;
      localStorage.setItem('audioVolume', JSON.stringify(this.volume ?? 1));
    }
  }
});
