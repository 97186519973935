import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "imagecontainer",
  class: "d-block",
  style: {"width":"100%"}
}
const _hoisted_2 = { class: "d-flex flex-column" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "smaller mt-2 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.imageUrl,
        class: "img-fluid mx-auto",
        style: {"height":"280px","border-radius":"10px"}
      }, null, 8, _hoisted_3),
      (_ctx.description)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t("messages.License:")) + " " + _toDisplayString(_ctx.description), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}