
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    width: {
      type: Number,
      required: true
    }
  }
});
