
import { defineComponent, PropType } from 'vue';
import WordList from '@/components/student/study/WordList.vue';
import WordCluster from '@/components/student/study/WordCluster.vue';
import OneOfThree from '@/components/student/study/OneOfThree.vue';
import ImageOneOfThree from '@/components/student/study/ImageOneOfThree.vue';
import WriteWord from '@/components/student/study/WriteWord.vue';
import ImageWriteWord from '@/components/student/study/ImageWriteWord.vue';
import ImageAudioWriteWord from '@/components/student/study/ImageAudioWriteWord.vue';
import ImageAudioOneOfThree from '@/components/student/study/ImageAudioOneOfThree.vue';
import StudyMusic from '@/components/student/study/StudyMusic.vue';
import ProgressBar from '@/components/student/study/ProgressBar.vue';

import {
  emptyStage, imageAudioOneOfThree, imageAudioWriteWord,
  imageOneOfThree, imageWriteWord,
  LearningMethod,
  oneOfThree,
  Settings,
  Stage,
  Study, StudyGuess,
  Superlearning,
  Translation, wordCluster, writeWord
} from '@/models';
import { getRandomWeightedValues, shuffle } from '@/components/student/study/StudyMethods';
import Swal from '@/sweetalert2';
import { showWrongWordToast } from '@/sweetalert2/templates';
import { SweetAlertResult } from 'sweetalert2';

export default defineComponent({
  props: {
    allTranslations: {
      type: Object as PropType<Translation[]>,
      required: true
    },
    lessonIds: {
      type: Object as PropType<number[]>,
      required: true
    }
  },
  emits: ['trainAgain'],
  components: {
    WordList,
    WordCluster,
    ImageOneOfThree,
    ImageAudioOneOfThree,
    OneOfThree,
    ImageWriteWord,
    ImageAudioWriteWord,
    WriteWord,
    StudyMusic,
    ProgressBar
  },
  async created () {
    const settings = await Settings.getUserSettings();
    if (settings.superlearningId != null) {
      this.superlearning = await Superlearning.getById(settings.superlearningId);
    }
  },
  data () {
    return {
      superlearning: {} as Superlearning,
      currentTranslations: [] as Translation[],
      amountTranslations: 10,
      usedTranslations: [] as Translation[],
      stageCounter: 0,
      cardCounter: -1,
      allCards: 0,
      correctCards: 0,
      audioPlaying: 0,
      lastGuess: { translation: {} as Translation, correct: false },
      wrongToast: {} as SweetAlertResult,
      stages: [
        emptyStage,
        oneOfThree,
        // imageOneOfThree,
        imageAudioOneOfThree,
        wordCluster,
        writeWord,
        // Commented out to disable Learningmethod ImageWriteWord
        // imageWriteWord,
        imageAudioWriteWord
      ] as Stage[]
    };
  },
  computed: {
    countProgress () {
      return this.cardCounter / this.allCards;
    },
    currentStage () {
      if (this.stageCounter > this.stages.length - 1) {
        return emptyStage as Stage;
      }
      return this.stages[this.stageCounter];
    }
  },
  async mounted () {
    this.amountTranslations = this.$store.state.userSettings.vocabsPerStage;
    const amountTranslations = Math.min(this.amountTranslations, this.allTranslations.length);
    for (const translation of this.allTranslations) {
      translation.invertedValue = 101 - translation.level;
    }
    this.usedTranslations = getRandomWeightedValues(this.allTranslations, amountTranslations);
    this.usedTranslations.forEach((t: Translation) => {
      const stage = this.getStage(t);
      this.stages[stage].allTranslations.push(t);
    });
    this.allCards = this.getCardCount();
    await this.displayNewCard();
  },
  beforeUnmount () {
    Swal.close();
  },
  methods: {
    sendGuess ({ translation, term, correct }: StudyGuess) {
      Study.learn(translation, term);
      this.lastGuess = { translation, correct };
      if (!this.lastGuess.correct) {
        showWrongWordToast(`${this.$t("messages.wrong translation")} "${translation.fromName}" ${this.$t("messages.is")} "${translation.toName}".`,
          this.$refs['swal-container'] as HTMLElement);
      }
    },
    getStage (translation: Translation): number {
      const hasAudio = translation.toAudioUrl !== '' && translation.toAudioUrl != null;
      const hasImage = translation.imageUrl !== '' && translation.imageUrl != null;
      const qualifiesFor = this.stages.filter(s =>
        s.method !== LearningMethod.none &&
        s.minLevel <= translation.level &&
        s.maxLevel >= translation.level &&
        (!s.requiresAudio || hasAudio) &&
        (!s.requiresImage || hasImage));
      const stageNumber = Math.floor(Math.random() * qualifiesFor.length);
      if (qualifiesFor.length === 0) {
        return this.stages.findIndex(s => s.method === LearningMethod.oneOfThree);
      }

      return this.stages.findIndex(s => s.method === qualifiesFor[stageNumber].method);
    },
    async displayNewCard (lastCardCorrect?: boolean) {
      if (lastCardCorrect !== undefined && lastCardCorrect) {
        this.correctCards++;
      }

      const available = this.currentStage.allTranslations.length;
      if (available === 0) {
        if (this.stageCounter >= this.stages.length) {
          this.cardCounter++;
          await this.finished();
        } else {
          this.stageCounter++;
          await this.displayNewCard();
        }

        return;
      }
      this.cardCounter++;

      this.setTranslations(available);
    },
    setTranslations (available: number) {
      const stageMax = this.currentStage.usesAmount;
      const used = Math.min(available, this.currentStage.learnsAmount);
      const currentWords: Translation[] = this.currentStage.allTranslations.splice(0, used);
      const notInCurrent = this.allTranslations.filter((t: Translation) => !currentWords.includes(t));
      const randomValues = getRandomWeightedValues(notInCurrent, stageMax - used);
      this.currentStage.usesTranslations = shuffle<Translation>([...currentWords, ...randomValues]);
      this.currentStage.learnsTranslations = currentWords;
    },
    getCardCount () {
      let counter = 0;
      for (let i = 1; i < this.stages.length; i++) {
        counter += Math.ceil(this.stages[i].allTranslations.length / this.stages[i].learnsAmount);
      }
      return counter;
    },
    async finished () {
      if (!this.lastGuess.correct) {
        await showWrongWordToast(`${this.$t("messages.wrong translation")} "${this.lastGuess.translation.fromName}" ${this.$t("messages.is")} "${this.lastGuess.translation.toName}".`,
          this.$refs['swal-container'] as HTMLElement);
      }
      const response = await Swal.fire({
        icon: 'success',
        title: this.$t("messages.training completed"),
        text: `${this.$t("messages.you have")} ${this.correctCards} ${this.$t("messages.of")} ${this.allCards} ${this.$t("messages.exercises solved")}`,
        showDenyButton: true,
        confirmButtonText: this.$t("messages.Repeat Lecture"),
        denyButtonText: this.$t("messages.Back to the lesson")
      });

      if (response.isDenied) {
        await this.$router.push(`/lesson/${this.lessonIds[0]}`);
      } else {
        this.$emit('trainAgain');
      }
    }

  }
});
