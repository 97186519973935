import { backendUrl } from '@/models';

export default function playAudio (link: string): Promise<void> {
  if (link === backendUrl + 'null') { return new Promise<void>(() => undefined); }
  const audio = new Audio(link);
  audio.play().catch(() => true);
  return new Promise((resolve) => {
    audio.addEventListener('error', () => {
      resolve();
    });
    audio.addEventListener('loadedmetadata', () => {
      const duration = audio.duration;
      setTimeout(() => resolve(), duration * 1000);
    }, false);
  });
}
