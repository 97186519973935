
import { defineComponent, PropType } from 'vue';
import { Translation } from '@/models';
import { checkCorrect, shuffle } from '@/components/student/study/StudyMethods';
import { audioMixin } from '@/mixins/audioMixin';

interface ClusterElement {
  checked: boolean;
  visible: boolean;
  incorrect: boolean;
  from: boolean;
  vocab: { id: number, name: string }
}

export default defineComponent({
  data () {
    return {
      clusterElements: [] as ClusterElement[],
      flawless: true
    };
  },
  props: {
    translations: {
      type: Object as PropType<Translation[]>,
      required: true
    }
  },
  mounted () {
    this.assignClusterElements(this.translations);
    document.addEventListener('keyup', this.keyListener, false);
  },
  unmounted () {
    document.removeEventListener('keyup', this.keyListener, false);
  },
  mixins: [audioMixin],
  emits: ['checkOne', 'submitted'],
  watch: {
    translations (newValue: Translation[]) {
      this.assignClusterElements(newValue);
      this.flawless = true;
    }
  },
  methods: {
    assignClusterElements (translationArray: Translation[]) {
      const elements = [];

      for (let i = 0; i < translationArray.length; i++) {
        const translation = translationArray[i];
        elements.push({
          checked: false,
          visible: true,
          incorrect: false,
          from: true,
          vocab: { id: translation.fromId, name: translation.fromName }
        });
        elements.push({
          checked: false,
          visible: true,
          incorrect: false,
          from: false,
          vocab: { id: translation.toId, name: translation.toName }
        });
      }

      this.clusterElements = shuffle<ClusterElement>(elements);
    },
    check (element: ClusterElement) {
      element.checked = !element.checked;
      const checkedElements = this.clusterElements.filter(e => e.visible && e.checked);
      if (checkedElements.length !== 2) {
        return;
      }
      const [elem1, elem2] = checkedElements;
      const translation1 = this.translations.find(t => t.fromId === elem1.vocab.id || t.toId === elem1.vocab.id);
      const translation2 = this.translations.find(t => t.fromId === elem2.vocab.id || t.toId === elem2.vocab.id);
      if ((
        (elem1.from && !elem2.from) || (elem2.from && !elem1.from)) &&
        checkCorrect(translation1, translation2)) {
        elem1.visible = false;
        elem2.visible = false;
        this.playVocab(elem1.from ? translation2 : translation1);
        this.$emit('checkOne', { term: translation1?.toName, translation: translation1, correct: true });
      } else {
        elem1.checked = false;
        elem2.checked = false;

        elem1.incorrect = true;
        elem2.incorrect = true;

        setTimeout(() => {
          elem1.incorrect = false;
          elem2.incorrect = false;
        }, 1000);
        this.$emit('checkOne', { term: elem1.vocab.name, translation: translation2, correct: false });
        this.$emit('checkOne', { term: elem2.vocab.name, translation: translation1, correct: false });
        this.flawless = false;
      }

      if (this.clusterElements.reduce((a, b) => a + (+b.visible), 0) === 0) {
        this.$emit('submitted', this.flawless);
      }
    },
    keyListener (e: KeyboardEvent) {
      const validKeys = ['1', '2', '3', '4', '5', '6'];
      if (!validKeys.includes(e.key)) {
        return;
      }
      const visibleElements = this.clusterElements.filter(e => e.visible);
      const index = parseInt(e.key) - 1;
      if (index >= visibleElements.length) { return; }
      this.check(visibleElements[index]);
    }
  }
});
