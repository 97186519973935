
import { defineComponent, PropType } from 'vue';
import { Translation } from '@/models';
import { levDist } from '@/components/student/study/StudyMethods';
import ImageContainer from '@/components/student/study/ImageContainer.vue';
import { audioMixin } from '@/mixins/audioMixin';

export default defineComponent({
  components: { ImageContainer },
  props: {
    translation: {
      type: Object as PropType<Translation>,
      required: true
    }
  },
  data () {
    return {
      guessInput: ''
    };
  },
  mixins: [audioMixin],
  emits: ['submitted', 'checkOne'],
  watch: {
    translation () {
      this.guessInput = '';
      (this.$refs.inputField as HTMLInputElement).focus();
    }
  },
  methods: {
    checkWrite () {
      const userInput = this.guessInput.trim().toLocaleLowerCase();
      const solution = this.translation?.toName.trim().toLocaleLowerCase();
      const correct = levDist(userInput, solution) <= 1;
      this.playVocab(this.translation);
      this.$emit('checkOne', { translation: this.translation, term: this.guessInput, correct });
      this.$emit('submitted', correct);
    }
  }
});
