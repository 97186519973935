
import { defineComponent, PropType } from 'vue';
import { Translation } from '@/models';
import ImageContainer from '@/components/student/study/ImageContainer.vue';
import { audioMixin } from '@/mixins/audioMixin';
import { checkCorrect } from '@/components/student/study/StudyMethods';

export default defineComponent({
  components: { ImageContainer },
  props: {
    translations: {
      required: true,
      type: Object as PropType<Translation[]>
    },
    learns: {
      required: true,
      type: Object as PropType<Translation>
    }
  },
  data () {
    return {
      displayNumber: 0
    };
  },
  emits: ['submitted', 'checkOne'],
  mixins: [audioMixin],
  mounted () {
    this.assignDisplayNumber(this.translations, this.learns);
    this.addKeyListener();
  },
  unmounted () {
    document.removeEventListener('keyup', this.eventHandler, false);
  },
  watch: {
    translations (newTranslations: Translation []) {
      this.assignDisplayNumber(newTranslations, this.learns);
    },
    learns (newLearns: Translation) {
      this.assignDisplayNumber(this.translations, newLearns);
    }
  },
  methods: {
    check (translation: Translation) {
      let requestedTranslation = this.translations[this.displayNumber];
      const correct = checkCorrect(translation, requestedTranslation);
      if (correct) {
        requestedTranslation = translation;
      }
      this.playVocab(requestedTranslation);
      this.$emit('checkOne', { translation: requestedTranslation, term: translation.toName, correct });
      this.$emit('submitted', correct);
    },
    assignDisplayNumber (translations: Translation[], translation: Translation) {
      this.displayNumber = translations.findIndex(t => t.fromId === translation.fromId) || 0;
    },
    addKeyListener () {
      document.addEventListener('keyup', this.eventHandler, false);
    },
    eventHandler (event: KeyboardEvent) {
      const validKeys = ['1', '2', '3'];
      if (validKeys.includes(event.key)) {
        this.check(this.translations[parseInt(event.key) - 1]);
      }
    }
  }
});

